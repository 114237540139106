<template>
  <div class="faq">
    <Header />
    <div class="banner">
      <div class="container">
        <div class="title">Frequently Asked Questions</div>
      </div>
    </div>
    <Faq />
    <Fast />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import Faq from "@/components/faq/Faq";
import Fast from "@/components/fast/Fast";
export default {
  components: {
    Header,
    Footer,
    Faq,
    Fast,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.faq {
  width: 100%;
  position: relative;
  .banner {
    width: 100%;
    height: 286px;
    background: url("~@/assets/images/banner_3.png") no-repeat;
    background-size: cover;
    background-position: center;
    padding: 160px 0 80px 0;
    box-sizing: border-box;
    .title {
      font-size: 40px;
      font-family: Ubuntu-Bold, Ubuntu;
      font-weight: bold;
      color: #ffffff;
      line-height: 47px;
      text-align: center;
    }
  }
}
@media only screen and (max-width: 690px) {
  .faq {
    .banner {
      height: 132px;
      padding: 84px 0 20px 0;
      .title {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}
@media only screen and (max-width: 1050px) and (min-width: 690px) {
  .faq {
    .banner {
      height: 132px;
      padding: 84px 0 20px 0;
      .title {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}
</style>
