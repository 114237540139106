<template>
  <div class="faq">
    <div class="container">
      <div class="warpper">
        <div class="title">Frequently Asked Questions</div>
        <div class="faq-info">
          <div class="asq-item" v-for="(item, index) in asq" :key="index">
            <div class="question" @click="asqClick(index)">
              <div class="left">{{ item.question }}</div>
              <img src="~@/assets/images/arr_t.png" alt="" v-if="item.type" />
              <img
                src="~@/assets/images/arr_bottom.png"
                alt=""
                v-if="!item.type"
              />
            </div>
            <div class="answer" v-show="item.type">
              {{ item.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      asq: [
        {
          question: "How much can I get for my car?",
          type: false,
          answer:
            "Once we receive your request, our team will analyze your car and get back to you via phone or email. We will offer an initial price and send a team member to verify the condition of the vehicle. They will then confirm the amount you’ll get for it post on-site inspection.",
        },
        {
          question: "How do I get paid when my car sells?",
          type: false,
          answer:
            "We prefer the fastest method for your payment – a bank transfer by Osko. Never fear that once we reach a deal for your car, your money will be processed straight away, even on weekends and public holidays. You should receive your money within 24-hours.",
        },
        {
          question: "Are there any documents I need to sell my car?",
          type: false,
          answer:
            "We’ll need to verify that you are the owner of the car. When we confirm your quote, we ask that you kindly provide your driver’s license and vehicle registration papers. If you are selling the vehicle on behalf of someone else, please provide the ID documents of the owner and an authorisation letter stating that you may sell.",
        },
        {
          question:
            "What should I do if there’s outstanding finance on my vehicle?",
          type: false,
          answer:
            "Yes, you can sell your car if there’s outstanding finance. However, you will need to supply us with an up-to-date payout letter from your financial institution before you can make the sale.",
        },
        {
          question: "When and how do you collect the car?",
          type: false,
          answer:
            "Once we finalize a deal for your car, we’ll set a time and date to pick it up at your convenience. One of our drivers will arrive in a toll truck to take your vehicle. Please ensure all keys, logbooks, manuals, and other essentials are inside the car.",
        },
        {
          question:
            "Do I need to provide Roadworthy Certificate or pink slip when I sell the car?",
          type: false,
          answer:
            "No, as we are a registered licensed motor dealer. No pink slip or RWC needed when you sell the car to us.",
        },
      ],
    };
  },
  methods: {
    asqClick(index) {
      this.asq[index].type = !this.asq[index].type;
    },
  },
};
</script>

<style scoped lang="less">
.faq {
  width: 100%;
  background: #fff;
  .warpper {
    padding: 120px 0;
    box-sizing: border-box;
    .title {
      font-size: 40px;
      font-family: Ubuntu-Bold, Ubuntu;
      font-weight: bold;
      color: #062748;
      line-height: 47px;
      text-align: center;
    }
    .faq-info {
      margin-top: 20px;
      .asq-item {
        width: 100%;
        padding: 20px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #eaeaea;
        .question {
          padding: 16px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          font-size: 16px;
          font-family: Ubuntu-Bold, Ubuntu;
          font-weight: bold;
          color: #062748;
          line-height: 19px;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .answer {
          height: auto;
          padding: 16px;
          box-sizing: border-box;
          font-size: 16px;
          font-family: Ubuntu-Regular, Ubuntu;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.66);
          line-height: 19px;
        }
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  .faq {
    .warpper {
      padding: 20px 0;
      .title {
        font-size: 24px;
        line-height: 28px;
      }
      .faq-info {
        margin-top: 10px;
        .asq-item {
          padding: 10px 0;
          .question {
            padding: 10px 0;
            font-size: 12px;
            line-height: 14px;
          }
          .answer {
            padding: 10px 0;
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) and (min-width: 690px) {
  .faq {
    .warpper {
      padding: 20px 0;
      .title {
        font-size: 24px;
        line-height: 28px;
      }
      .faq-info {
        margin-top: 10px;
        .asq-item {
          padding: 10px 0;
          .question {
            padding: 10px 0;
            font-size: 12px;
            line-height: 14px;
          }
          .answer {
            padding: 10px 0;
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }
  }
}
</style>
