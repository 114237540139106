<template>
  <div class="fast">
    <div class="container">
      <div class="warpper">
        <div class="left">
          <div class="title">We buy your cars no matter the make or model.</div>
          <div class="desc">
            Selling Your Car is a service that puts the worries and concerns of
            selling your vehicle behind you. If you are wondering where to sell
            my car for cash, simply contact us, and we’ll make the sale process
            seamless. We’re the most fluid and trusted resource for vehicle
            owners, putting cash into the pockets of Australians fast and
            efficiently.
          </div>
          <div class="btn" @click="backTop">Start Your Car Valuation</div>
        </div>
        <div class="right">
          <img src="~@/assets/images/fast.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    backTop() {
      window.scrollTo(0, 0);
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="less">
.fast {
  width: 100%;
  // height: 280px;
  background: #fff;
  .warpper {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 100%;
    padding: 148px 0;
    box-sizing: border-box;
    .left {
      width: 640px;
      margin-right: 80px;
      .title {
        font-size: 40px;
        font-family: Ubuntu-Bold, Ubuntu;
        font-weight: bold;
        color: #062748;
        line-height: 47px;
      }
      .desc {
        font-size: 16px;
        font-family: Ubuntu-Regular, Ubuntu;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.72);
        line-height: 19px;
        margin-top: 40px;
      }
      .btn {
        padding: 16px 24px;
        box-sizing: border-box;
        background: #0c61b9;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: Ubuntu-Bold, Ubuntu;
        font-weight: bold;
        color: #fff;
        line-height: 16px;
        user-select: none;
        transition: all 0.3s;
        cursor: pointer;
        width: 231px;
        margin-top: 40px;
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 20px 38px rgba(0, 0, 0, 0.2);
        }
      }
    }
    .right {
      width: 480px;
      height: 347px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  .fast {
    // height: 280px;
    .warpper {
      padding: 20px 0;
      flex-direction: column-reverse;

      .left {
        width: 100%;
        margin-right: 0px;
        .title {
          font-size: 24px;
          line-height: 28px;
          margin-top: 8px;
        }
        .desc {
          font-size: 12px;
          line-height: 14px;
          margin-top: 8px;
        }
        .btn {
          width: 100%;
          margin-left: 0;
          margin-top: 8px;
          text-align: center;
        }
      }
      .right {
        width: 100%;
        height: 120px;
      }
    }
  }
}
@media only screen and (max-width: 1050px) and (min-width: 690px) {
  .fast {
    // height: 280px;
    .warpper {
      padding: 20px 0;
      flex-direction: column-reverse;

      .left {
        width: 100%;
        margin-right: 0px;
        .title {
          font-size: 24px;
          line-height: 28px;
          margin-top: 8px;
        }
        .desc {
          font-size: 12px;
          line-height: 14px;
          margin-top: 8px;
        }
        .btn {
          width: 100%;
          margin-left: 0;
          margin-top: 8px;
          text-align: center;
        }
      }
      .right {
        width: 100%;
        height: 240px;
      }
    }
  }
}
</style>
